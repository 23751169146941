import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AnimalAddService} from "../services/animal-add.service";
import {MenuItem} from "primeng/api";
import {AnimalFilter} from "../animal-filters/animal-filter.model";
import {AnimalService} from "../services/animal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AnimalCategoryModel} from "../models/animal-category.model";
import {AnimalGenderModel} from "../models/animal-gender.model";
import {AnimalCoatTypeModel} from "../models/animal-coat-type.model";
import {AnimalTypeModel} from "../models/animal-type.model";
import {filter, map, take} from "rxjs";
import {CompetentAuthorityModel} from "../../../shared/models/competent-authority.model";
import {PersonTitleModel} from "../../../shared/models/person-title.model";
import {CompetentAuthoritiesService} from "../../../shared/services/competent-authorities.service";
import {UserRepository} from "../../user/states/user.repository";
import {ToastService} from "../../../shared/services/toast.service";
import {TranslocoService} from "@ngneat/transloco";
import {AnimalHintModel} from "../models/animal-hint.model";
import {AnimalOriginReasonForTransfer} from "../models/animal-origin-reason-for-transfer.enum";
import {AnimalOriginReasonForTransferModel} from "../models/animal-origin-reason-for-transfer.model";
import {AnimalCharacteristicsModel} from "../models/animal-characteristics.model";
import { AnimalSheltersRepository } from '../states/animal-shelter.repository';
import {AnimalRepository} from "../states/animal.repository";
import {HelperService} from "../../../shared/services/helper.service";
import { AnimalCategory } from '../models/animal-category.enum';
import { AnimalType } from '../models/animal-type.enum';

@Component({
  selector: 'app-animal-add-dialog',
  templateUrl: './animal-add-dialog.component.html',
  styleUrls: ['./animal-add-dialog.component.scss']
})
export class AnimalAddDialogComponent implements OnInit {
  visible: boolean = false;
  items: MenuItem[] = [];
  activeTabIndex: number = 0;
  selectedCategory!: AnimalFilter;

  formGroup: FormGroup;
  formGroupStatus$: any;

  categoryFilters: AnimalFilter[] = [];
  animalTypeOptions: AnimalTypeModel[] = [];
  allAnimalTypes: AnimalTypeModel[] = [];
  animalCategoryOptions: AnimalCategoryModel[] = [];
  coatTypeOptions: AnimalCoatTypeModel[] = [];
  genderOptions: AnimalGenderModel[] = [];
  formSubmitted: boolean = false;
  originCompetentAuthorityOptions: CompetentAuthorityModel[] = [];
  originPersonSalutationOptions: PersonTitleModel[] = [];
  animalShelterId: number | undefined;
  originReasonForTransfer: AnimalOriginReasonForTransferModel[] = [{name: '', value: ''}];
  hintsOptions: AnimalHintModel[] | undefined;
  characteristicsOptions: AnimalCharacteristicsModel[] | undefined;

  constructor(
    public animalService: AnimalService,
    private _animalAddService: AnimalAddService,
    private _competentAuthoritiesService: CompetentAuthoritiesService,
    private _userRepository: UserRepository,
    private _toast: ToastService,
    private _translateService: TranslocoService,
    private _animalSheltersRepository: AnimalSheltersRepository,
    private cdr: ChangeDetectorRef,
    private _animalRepository: AnimalRepository,
    private _helperService: HelperService
  ) {
    this.formGroup = new FormGroup(
      {
        oldBookNumber: new FormControl<string | null>(null),
        animalShelter: new FormControl<string | null>(null),
        type: new FormControl<AnimalTypeModel | null>(null, Validators.required),
        category: new FormControl<AnimalCategoryModel | null>(null, Validators.required),
        sex: new FormControl<string | null>(null, Validators.required),
        receiptDate: new FormControl<Date | null>(null),
        name: new FormControl<string | null>(null),
        nameOld: new FormControl<string | null>(null),
        nameNew: new FormControl<string | null>(null),
        birthApprox: new FormControl<boolean | null>(false),
        birthdayType: new FormControl<boolean | null>(false),
        birthYear: new FormControl<Date | null>(null),
        birthday: new FormControl<Date | null>(null),
        breed: new FormControl<string | null>(null, Validators.required),
        coatType: new FormControl<AnimalCoatTypeModel[] | [null]>([null]),
        coatColor: new FormControl<string | null>(null),
        size: new FormControl<number | null>(null),
        transponderNumber: new FormControl<string | null>(null, Validators.required),
        lifeNumber: new FormControl<string | null>(null),
        ringNumber: new FormControl<string | null>(null),
        petPassportNumber: new FormControl<string | null>(null),
        equinePassportNumber: new FormControl<string | null>(null),
        earTag: new FormControl<string | null>(null),
        tattoo: new FormControl<string | null>(null),
        tattooLocation: new FormControl<string | null>(null),
        specialInformation: new FormControl<string | null>(null),
        neutered: new FormControl<boolean | null>(null),
        castrated: new FormControl<boolean | null>(false),
        castratedDate: new FormControl<Date | null>(null),
        castratedNote: new FormControl<string | null>(null),
        sterilizedDate: new FormControl<Date | null>(null),
        chemicalCastratedDate: new FormControl<Date | null>(null),
        hints: new FormControl<AnimalHintModel[] | null>(null),
        internalDetails: new FormControl<AnimalCharacteristicsModel[] | null>(null),
        externalDetails: new FormControl<AnimalCharacteristicsModel[] | null>(null),
        syncWithWebsite: new FormControl<boolean | null>(false),
        sanctuary: new FormControl<boolean | null>(false),
        fosterHomeWanted: new FormControl<boolean | null>(false),
        externalShortDescription: new FormControl<string | null>(null),
        externalDescription: new FormControl<string | null>(null),
        websiteMetaDescription: new FormControl<string | null>(null),
        websiteMetaTitle: new FormControl<string | null>(null),
        //Origin
        originCompetentAuthority: new FormControl<string | null>(null, Validators.required),
        originLocation: new FormControl<string | null>(null, Validators.required),
        originReasonForTransfer: new FormControl<string | null>(null, Validators.required),
        originDateOfReceipt: new FormControl<Date | null>(null, Validators.required),
        originSpecialInformation: new FormControl<string | null>(null),
        originPersonSalutation: new FormControl<PersonTitleModel | null>(null),
        originPersonFirstName: new FormControl<string>(''),
        originPersonLastName: new FormControl<string>(''),
        originPersonStreet: new FormControl<string>(''),
        originPersonZipCode: new FormControl<string>(''),
        originPersonCity: new FormControl<string>(''),
        originPersonCompanyName: new FormControl<string>(''),
        originPersonPhoneNumber: new FormControl<string>(''),
        originPersonMobileNumber: new FormControl<string>(''),
        originPersonEmail: new FormControl<string>(''),
        originPersonIdCardNumber: new FormControl<string>(''),
        originConditionOfCare: new FormControl<string | null>(null),
        originNutritionalStatus: new FormControl<string | null>(null),
        originOtherComments: new FormControl<string | null>(null),
      },
      {
        updateOn: 'change',
      }
    );

    this.formGroup.get('castratedDate')?.valueChanges.subscribe(() => {
      this.onCastratedDatesChange();
    });

    this.formGroup.get('chemicalCastratedDate')?.valueChanges.subscribe(() => {
      this.onCastratedDatesChange();
    });
  }

  closeModal() {
    this._animalAddService.toggleAddDialog(false);
  }

  lastStep() {
    if (this.activeTabIndex > 0) {
      this.activeTabIndex--;
    }
  }

  nextStep() {
    if (this.activeTabIndex < 2) {
      this.activeTabIndex++;
    }
  }

  selectCategory(filter: AnimalFilter) {
    const category = this.mapFilterIdToCategory(filter.id);
    this.selectedCategory = filter;
    if (category) {
      this.formGroup.get('category')?.setValue(category);

      this.animalTypeOptions = this.filterAnimalTypesByCategory(category);

      if (this.animalTypeOptions.length > 0) {
        this.formGroup.get('type')?.setValue(this.animalTypeOptions[0].value);
      } else {
        this.formGroup.get('type')?.setValue('');
      }
    }
  }

  mapFilterIdToCategory(id: string): AnimalCategory | undefined {
    const categoryMap: { [key: string]: AnimalCategory } = {
      dog: AnimalCategory.DOG,
      cat: AnimalCategory.CAT,
      horse: AnimalCategory.HORSE,
      small_animal: AnimalCategory.SMALL_ANIMAL,
      reptile: AnimalCategory.REPTILE,
      wild_animal: AnimalCategory.WILD_ANIMAL,
      farm_animal: AnimalCategory.FARM_ANIMAL,
      bird: AnimalCategory.BIRD,
    };

    return categoryMap[id.toLowerCase()];
  }

  filterAnimalTypesByCategory(category: AnimalCategory): AnimalTypeModel[] {
    const animalCategoryTypeMap = {
      [AnimalCategory.DOG]: [AnimalType.DOG],
      [AnimalCategory.CAT]: [AnimalType.CAT],
      [AnimalCategory.HORSE]: [AnimalType.HORSE, AnimalType.DONKEY, AnimalType.ALPACA],
      [AnimalCategory.SMALL_ANIMAL]: [AnimalType.FERRET, AnimalType.RODENT, AnimalType.HARE, AnimalType.MARTEN, AnimalType.TENREC],
      [AnimalCategory.REPTILE]: [AnimalType.TURTLE, AnimalType.LIZARD, AnimalType.SNAKE],
      [AnimalCategory.WILD_ANIMAL]: [AnimalType.BEAR, AnimalType.ELK],
      [AnimalCategory.FARM_ANIMAL]: [AnimalType.PIG],
      [AnimalCategory.BIRD]: [AnimalType.BIRD],
    };

    const allowedTypes = animalCategoryTypeMap[category] || [];

    return this.allAnimalTypes.filter(typeModel =>
      allowedTypes.includes(typeModel.value as AnimalType)
    );
  }

  shouldDisableTypeDropdown(): boolean {
    return this.animalTypeOptions.length === 1;
  }

  onCategoryDropdownChange(event: any) {
    const selectedCategory = event.value;
    const filter = this.categoryFilters.find(filter => filter.id.toLowerCase() === selectedCategory);
    if (filter) {
      this.selectCategory(filter);
    }
  }

  isSelectedCategory(filter: AnimalFilter) {
    return this.selectedCategory === filter;
  }

  getInvalidFormControls() {
    return Object.keys(this.formGroup.controls).filter(key => this.formGroup.controls[key].invalid);
  }

  ngOnInit(): void {
    const activeAnimalShelter = this._animalSheltersRepository.getActiveAnimalShelter();
    if (activeAnimalShelter) {
      this._competentAuthoritiesService.getCompetentAuthoritiesByShelterId(activeAnimalShelter.id).pipe(take(1)).subscribe((competentAuthorities) => {
        this.originCompetentAuthorityOptions = competentAuthorities;
        this.cdr.detectChanges();
      });
    }
    this.items = [
      { label: 'Kategorie' },
      { label: 'Allgemein' },
      { label: 'Herkunft' },
    ];

    this.originPersonSalutationOptions = this._helperService.getSalutationOptions();

    this.formGroupStatus$ = this.formGroup.statusChanges.pipe(
      map((status) => status)
    );

    this.categoryFilters = this.animalService.getFilterCategories();
    this.allAnimalTypes = this.animalService.getAnimalTypes();
    this.animalCategoryOptions = this.animalService.getAnimalCategories();
    this.genderOptions = this.animalService.getGenderOptions();
    this.coatTypeOptions = this.animalService.getCoatTypes();
    this.hintsOptions = this.animalService.getHints();
    this.characteristicsOptions = this.animalService.getCharacteristics();
    this.visible = true;

    this._userRepository.activeUser$.pipe(take(1), filter((v) => v === v)).subscribe(user => {
      this.animalShelterId = user?.defaultAnimalShelter?.id;
    });

    for (const reasonForTransfer in AnimalOriginReasonForTransfer) {
      this.originReasonForTransfer.push({
        name: this._translateService.translate(`animalAdd.origin.reasonForTransferSelect.${reasonForTransfer}`),
        value: reasonForTransfer
      });
    }
  }

  saveChanges() {
    this.formSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (!this.animalShelterId) {
      console.error('No animalShelterId found');
      // TODO: Whats going on if NO animalShelterId is found?
      return;
    }

    if (this.formGroup.get('size')?.value && this.formGroup.get('size')?.value !== 0) {
      this.formGroup.patchValue({size: this.formGroup.get('size')?.value * 10});
    } else {
      this.formGroup.patchValue({size: null});
    }

    const birthYear = this.formGroup.get('birthYear')?.value as string;

    this.formGroup.get('birthdayType')?.value ?
      this.formGroup.patchValue({
        birthday: null,
        birthYear: new Date(new Date(birthYear).getFullYear(), 0, 1)
      }) :
      this.formGroup.patchValue({birthYear: null});

    if (this.formGroup.get('castratedDate')?.value) {
      this.formGroup.patchValue({castrated: true});
    }

    if (this.formGroup.get('chemicalCastratedDate')?.value) {
      this.formGroup.patchValue({castrated: true});
    }

    this.formGroup.patchValue({animalShelter: {id: this.animalShelterId}});
    this._animalRepository.create(this.formGroup.value)
      .then(newAnimalId => {
        if (newAnimalId !== undefined && newAnimalId !== null) {
          this._animalAddService.saveOrigin(this.formGroup.value, newAnimalId);
          this._toast.showSuccess(this._translateService.translate('animalAdd.toast.saveSuccess'));
          this.closeModal();
        }
      })
      .catch(error => {
        console.error('Error saving animal:', error);
      });
  }
  onCastratedDatesChange() {
    const castratedDate = this.formGroup.get('castratedDate')?.value;
    const chemicalCastratedDate = this.formGroup.get('chemicalCastratedDate')?.value;

    if (castratedDate || chemicalCastratedDate) {
      this.formGroup.get('castrated')?.setValue(true);
    } else {
      this.formGroup.get('castrated')?.setValue(false);
    }
  }
}
