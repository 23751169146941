import {createStore} from '@ngneat/elf';
import {
  withEntities,
  setEntities,
  withActiveId,
  upsertEntities,
  selectManyByPredicate, updateEntities,
} from '@ngneat/elf-entities';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {RestService} from "../../../shared/services/rest.service";
import {tap, switchMap} from "rxjs/operators";
import {AnimalWhereaboutModel} from '../models/animal-whereabout.model';
import {Observable, of} from 'rxjs';

const storeName = 'animal-whereabouts';

export const store = createStore(
  {name: storeName},
  withEntities<AnimalWhereaboutModel>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({providedIn: 'root'})
export class AnimalWhereaboutsRepository {
  constructor(
    private _restService: RestService
  ) {
    // silence is golden
  }

  getByAnimalId(animalId: number | undefined): Observable<any> {
    if (animalId === undefined) {
      return of(undefined);
    }
    return store.pipe(
      selectManyByPredicate(entity => entity?.animal?.id === animalId),
      switchMap(entities => {
        if (entities.length > 0) {
          return of(entities);
        } else {
          return this._restService.httpGet(`/animal_whereabouts?animal.id=${animalId}`)
            .pipe(
              tap(animalWhereabouts => {
                store.update(setEntities([animalWhereabouts]));
              })
            );
        }
      })
    );
  }

  getByAnimalIdFromApi(animalId: number | undefined):Observable<any> {
    if (animalId === undefined) {
      return of(undefined);
    }
    return this._restService.httpGet(`/animal_whereabouts?animal.id=${animalId}`);
  }

  getAll() {
    return this._restService.httpGet('/animal_whereabouts')
      .pipe(
        tap((whereabouts) => {
          this.setAll(whereabouts);
        })
      );
  }

  setAll(animalWhereabouts: AnimalWhereaboutModel[]) {
    store.update(setEntities(animalWhereabouts));
  }

  create(payload: any) {
    return this._restService.httpPost('/animal_whereabouts', payload)
      .pipe(
        tap((response) => {
          if (response && response.id) {
            store.update(upsertEntities(response.animal.id, response));
          }
        })
      );
  }

  update(id: string, payload: any) {
    return this._restService.httpPut('/animal_whereabouts/' + id, payload)
      .pipe(
        tap((response) => {
          if (response && response.id) {
            store.update(upsertEntities(response.animal.id, response));
          }
        })
      );
  }
}
