import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {AnimalModel} from "../models/animal.model";
import {AnimalImageModel} from "../models/animal-image.model";
import {BehaviorSubject, take, takeWhile} from "rxjs";
import {AnimalImagesRepository} from "../states/animal-images.repository";
import { AnimalEditService } from '../services/animal-edit.service';
import { AnimalRepository } from '../states/animal.repository';
import { ToastService } from 'src/app/shared/services/toast.service';
import { TranslocoService } from '@ngneat/transloco';
import {ConfirmationService} from "primeng/api";
import { AnimalImageCategoryModel } from '../models/animal-image-category.model';
import { getAnimalImageCategoryEnumKey } from '../models/animal-image-category.enum';
import { AnimalImageService } from '../services/animal-image.service';


@Component({
  selector: 'app-animal-details-images',
  templateUrl: './animal-details-images.component.html',
  styleUrls: ['./animal-details-images.component.scss'],
  providers: [ConfirmationService]
})
export class AnimalDetailsImagesComponent implements OnInit, OnDestroy {

  @Input() animal: AnimalModel | undefined;
  isAlive: boolean = true;
  images: AnimalImageModel[] = [];
  imageIds: number[] = [];
  selectedImage: AnimalImageModel | undefined;
  isEditingMode: boolean = false;
  selectedImgToDelete: number[] = [];
  selectedImgToDelete$ = new BehaviorSubject(this.selectedImgToDelete);

  displayDialog: boolean = false;
  deleteLoading: boolean = false;
  isPreview: boolean = false;
  unSaved: boolean = false;
  imageCategoryOptions: AnimalImageCategoryModel[] = [];

  protected readonly getImageCategoryEnumKey = getAnimalImageCategoryEnumKey;

  constructor(
    private _animalImagesRepository: AnimalImagesRepository,
    private cdr: ChangeDetectorRef,
    private _animalEditService: AnimalEditService,
    private _animalImageService: AnimalImageService,
    private _animalRepository: AnimalRepository,
    private _translateService: TranslocoService,
    private _toast: ToastService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    if (!this.animal) {
      return;
    }

    this._animalRepository.getById(this.animal.id).pipe(takeWhile(() => this.isAlive)).subscribe((animal) => {
      if (animal) {
        this.imageIds = Object.values(animal.images).map(image => image.id);
      }
    });

    this._animalImagesRepository.getImages(this.animal.id).pipe(take(1)).subscribe((images: AnimalImageModel[]) => {
      this.images = images;
    });

    this._animalImagesRepository.imageAdded$.pipe(takeWhile(() => this.isAlive)).subscribe(newImage => {
      if (newImage) {
        this.images.push(newImage);
      }
    });

    this.imageCategoryOptions = this._animalImageService.getAnimalImageCategories();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this._animalImagesRepository.clearImageAdded();
  }

  openDialog(image: AnimalImageModel) {
    if (this.isEditingMode) {
      return;
    }
    this.selectedImage = image;
    this.displayDialog = true;
  }
  dummyTranslateFunction() {
    this._translateService.translate('messages.info');
    this._translateService.translate('messages.sharePointSync');
  }

  clickTogglePreview(image: AnimalImageModel) {
    if (!this.animal) {
      return;
    }
    this.images.forEach((img: AnimalImageModel) => {
      if (img.id === image.id) {
        img.preview = !img.preview;
      } else {
        img.preview = false;
      }
    });
    this._animalImagesRepository.updateImage(this.animal.id, image.id, {preview: true});
    this.cdr.detectChanges();
  }


  deleteImage(selectedImage: AnimalImageModel) {
    this.confirmationService.confirm({
      key: 'deleteImage' + selectedImage.id,
      message: this._translateService.translate('delete_confirmation'),
      acceptLabel: this._translateService.translate('yes'),
      rejectLabel: this._translateService.translate('no'),
      header: '',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"none",
      rejectIcon:"none",

      accept: () => {
        this.deleteLoading = true;
        if (!this.animal) {
          return;
        }
        this._animalImagesRepository.deleteImage(selectedImage.id).pipe(take(1)).subscribe(() => {
          this.deleteLoading = false;
          this.displayDialog = false;
          this.images = this.images.filter((image: AnimalImageModel) => image.id !== selectedImage.id);
          if (this.animal) {
            this._animalRepository.getById(this.animal.id);
          }
          this._toast.showSuccess(this._translateService.translate('toast.deleteSuccess'));
        });
      },
      reject: () => {
        // rejected
      }
    });
  }

  selectToDelete(id: number) {
    if (this.selectedImgToDelete.includes(id)) {
      this.selectedImgToDelete = this.selectedImgToDelete.filter((imgId: number) => imgId !== id);
    } else {
      this.selectedImgToDelete.push(id);
    }
    this.selectedImgToDelete$.next(this.selectedImgToDelete);
  }

  clickFileExport(image: AnimalImageModel) {
    if (!this.animal) {
      return;
    }
    image.syncWithSharedFolder = !image.syncWithSharedFolder;
    this._animalImagesRepository.updateImage(this.animal.id, image.id, {syncWithSharedFolder: image.syncWithSharedFolder});
    this.cdr.detectChanges();
  }

  clickFileSync(image: AnimalImageModel) {
    if (!this.animal) {
      return;
    }
    image.syncWithWebsite = !image.syncWithWebsite;
    this._animalImagesRepository.updateImage(this.animal.id, image.id, {syncWithWebsite: image.syncWithWebsite});
    this.cdr.detectChanges();
  }

  onCategoryChange(image: AnimalImageModel) {
    if (!this.animal) {
      return;
    }
    this._animalImagesRepository.updateImage(this.animal.id, image.id, {categories: image.categories});
    this.cdr.detectChanges();
  }

  onSave() {
    // if (!this.selectedImage$ || !this.animal) {
    //   return;
    // }
    // this.selectedImage$.pipe(take(1)).subscribe((image: AnimalImageModel | undefined) => {
    //   if (!image || !this.animal) {
    //     return;
    //   }
    //   this._animalImagesRepository.updateImage(this.animal.id, image.id, {preview: this.isPreview});
    //   this.displayDialog = false;
    //   this.cdr.detectChanges();
    // });
  }

  

  toggleEditDialog() {
    this._animalEditService.toggleEditDialog({ images: true });
  }
}