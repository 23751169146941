<ng-container *ngFor="let animalOrigItem of animalOrigItems | keyvalue">
  <div
    class="card details-view"
    *ngIf="
      openTab['all'] ||
      openTab['origin'] === animalOrigItem.key ||
      !isEditingMode
    ">
    <form *ngIf="formGroupOrigins && formGroupOrigins[animalOrigItem.key]" [formGroup]="formGroupOrigins[animalOrigItem.key]">
      <div class="grid">
        <div class="col-12" *ngIf="!isEditingMode">
          <div class="flex">
            <div class="flex-1 p-4 text-xl font-semibold">
              {{ 'animalOrigin.general.headline' | transloco }} |
              {{ animalOrigItem?.value?.contact?.firstName }}
              {{ animalOrigItem?.value?.contact?.lastName }}
              {{ animalOrigItem?.value?.dateOfReceipt | date : 'dd.MM.yyyy' }}
            </div>
            <span class="flex-end">
              <button class="p-button p-button-sm" (click)="toggleEditDialog(animalOrigItem.key)">
                <i class="pi pi-pencil mr-1"></i>
                {{ 'edit' | transloco }}
              </button>
            </span>
          </div>
        </div>
        <div [ngClass]="isEditingMode ? 'col-6' : 'col-12'">
          <p-accordion [multiple]="true" [activeIndex]="[0]">
            <p-accordionTab
              header="{{ 'animalDetails.general.headline' | transloco }}"
            >
              <ng-template pTemplate="content">
                <div class="grid">
                  <div class="col-6">
                    <label class="py-1" for="competentAuthorityId">
                      {{ 'animalAdd.origin.competentAuthority' | transloco }}
                      <span *ngIf="isEditingMode" class="required">*</span>
                    </label>
                    <input *ngIf="!isEditingMode"
                      type="text"
                      class="w-full p-inputtext-sm"
                      [class.no-border]="!isEditingMode"
                      pInputText
                      [value]="cityName[animalOrigItem?.value?.competentAuthority?.id ?? '']"
                      disabled
                    />
                    <p-dropdown
                      *ngIf="isEditingMode"
                      class="p-inputdropdown-sm w-full"
                      id="competentAuthorityId"
                      [options]="originCompetentAuthorityOptions"
                      optionLabel="name"
                      optionValue="id"
                      [autoDisplayFirst]="false"
                      formControlName="competentAuthorityId"
                      [required]="true"
                      appendTo="body">
                  </p-dropdown>
                  </div>
                  <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.value?.location">
                    <label class="py-1" for="location">
                      {{ 'animalAdd.origin.location' | transloco }}
                      <span *ngIf="isEditingMode" class="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="w-full p-inputtext-sm"
                      [class.no-border]="!isEditingMode"
                      pInputText
                      formControlName="location"
                      id="location"
                    />
                  </div>
                  <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.value?.reasonForGiving">
                    <label class="py-1" for="originReasonForTransfer">
                      {{ 'animalAdd.origin.reasonForTransfer' | transloco }}
                      <span *ngIf="isEditingMode" class="required">*</span>
                    </label>
                    <p-dropdown
                      class="p-inputdropdown-sm w-full"
                      id="originReasonForTransfer"
                      [options]="originReasonForTransfer"
                      optionLabel="name"
                      [autoDisplayFirst]="false"
                      formControlName="reasonForTransfer"
                      appendTo="body"
                    >
                    </p-dropdown>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="dateOfReceipt">
                      {{ 'animalAdd.origin.dateOfReceipt' | transloco }}
                      <span class="required">*</span>
                    </label>
                    <p-calendar class="w-full p-inputcalendar-sm"
                                formControlName="dateOfReceipt"
                                id="dateOfReceipt"
                                dateFormat="dd.mm.yy" [showIcon]="true"
                                appendTo="body"></p-calendar>
                  </div>
                  <div class="col-12" *ngIf="isEditingMode || animalOrigItem?.value?.specialInformation">
                    <label class="py-1" for="specialInformation">{{
                      'animalAdd.origin.specialInformation' | transloco
                    }}</label>
                    <textarea
                      pInputText
                      class="p-inputtext-sm w-full"
                      area
                      formControlName="specialInformation"
                      id="specialInformation"
                      rows="5"
                    ></textarea>
                  </div>
                  <div class="col-6" *ngIf="isEditingMode">
                    <label class="py-1 mb-2" for="originWasGiven">{{
                      'animalAdd.origin.wasGiven' | transloco
                    }}</label>
                    <p-inputSwitch
                    id="originWasGiven"
                    [(ngModel)]="tempOriginWasGiven[animalOrigItem.key]"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="wasGivenChanged($event, animalOrigItem.key)"
                  ></p-inputSwitch>
                  </div>
                  <p-dialog [header]="'messages.info' | transloco" [modal]="true" [(visible)]="clearContactDialog" [style]="{ width: '25rem' }">
                    <span class="p-text-secondary block mb-5">{{ 'animalOrigin.removeContactDetails' | transloco }}</span>
                    <div class="flex justify-content-end gap-2">
                        <p-button [label]="'modal.cancel' | transloco" size="small" severity="secondary" (onClick)="onDialogCancel()" />
                        <p-button [label]="'modal.ok' | transloco" size="small" (onClick)="removeContactDetails()" />
                    </div>
                </p-dialog>
                  <ng-container *ngIf="originWasGiven[animalOrigItem.key]">
                    <div class="col-12 mt-3">
                      <span class="section-header font-semibold">{{ 'animalAdd.origin.transferBy' | transloco }}</span>
                    </div>
                    <div class="col-3" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.gender">
                      <label class="py-1" for="personSalutation">
                        {{ 'animalAdd.origin.person.salutation' | transloco }}
                      </label>
                      <p-dropdown
                        class="p-inputdropdown-sm w-full"
                        [options]="originSubmittedPersonSalutation"
                        optionLabel="name"
                        optionValue="value"
                        [autoDisplayFirst]="false"
                        formControlName="personSalutation"
                        id="personSalutation"
                        appendTo="body">
                      </p-dropdown>
                    </div>
                    <div class="col-4" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.lastName">
                      <label class="py-1" for="personLastName">
                        {{ 'animalAdd.origin.person.lastName' | transloco }}
                      </label>
                      <input type="text" class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personLastName"
                        id="personLastName"
                        pInputText
                      />
                    </div>
                    <div class="col-5" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.firstName">
                      <label class="py-1" for="personFirstName">
                        {{ 'animalAdd.origin.person.firstName' | transloco }}
                      </label>
                      <input type="text" class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personFirstName"
                        id="personFirstName"
                        pInputText
                      />
                    </div>
                    <div class="col-12" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.companyName">
                      <label class="py-1" for="companyName">
                        {{ 'animalAdd.origin.person.companyName' | transloco }}
                      </label>
                      <input type="text" class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personCompanyName"
                        id="companyName"
                        pInputText
                      />
                    </div>
                    <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.street">
                      <label class="py-1" for="personStreet">
                        {{ 'animalAdd.origin.person.street' | transloco }}
                      </label>
                      <input type="text" class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personStreet"
                        id="personStreet"
                        pInputText
                      />
                    </div>
                    <div class="col-2" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.zipCode">
                      <label class="py-1" for="personZipCode">
                        {{ 'animalAdd.origin.person.zipCode' | transloco }}
                      </label>
                      <input
                        type="text"
                        class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personZipCode"
                        id="personZipCode"
                        pInputText
                      />
                    </div>
                    <div class="col-4" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.city">
                      <label class="py-1" for="personCity">
                        {{ 'animalAdd.origin.person.city' | transloco }}
                      </label>
                      <input
                        type="text"
                        class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personCity"
                        id="personCity"
                        pInputText
                      />
                    </div>
                    <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.mobilePhoneNumber">
                      <label class="py-1" for="personMobileNumber">
                        {{ 'animalAdd.origin.person.mobileNumber' | transloco }}
                      </label>
                      <input
                        type="text"
                        class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personMobileNumber"
                        id="personMobileNumber"
                        pInputText
                      />
                    </div>
                    <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.phoneNumber">
                      <label class="py-1" for="personPhoneNumber">
                        {{ 'animalAdd.origin.person.phoneNumber' | transloco }}
                      </label>
                      <input
                        type="text"
                        class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personPhoneNumber"
                        id="personPhoneNumber"
                        pInputText
                      />
                    </div>
                    <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.email">
                      <label class="py-1" for="personEmail">
                        {{ 'animalAdd.origin.person.email' | transloco }}
                      </label>
                      <div class="p-inputgroup">
                        <span class="p-inputgroup-addon" [pTooltip]="'animalAdd.origin.person.email' | transloco" tooltipPosition="top">
                          <a class="absolute" *ngIf="animalOrigItem?.value?.contact?.email" [href]="'mailto:' + animalOrigItem?.value?.contact?.email">
                            <i class="pi pi-envelope"></i>
                          </a>
                        </span>
                        <input
                          type="text"
                          class="w-full p-inputtext-sm"
                          [class.no-border]="!isEditingMode"
                          formControlName="personEmail"
                          id="personEmail"
                          pInputText
                        />
                      </div>
                    </div>
                    <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.value?.contact?.idCardNumber">
                      <label class="py-1" for="personIdCardNumber">
                        {{ 'animalAdd.origin.person.idCardNumber' | transloco }}
                      </label>
                      <input
                        type="text"
                        class="w-full p-inputtext-sm"
                        [class.no-border]="!isEditingMode"
                        formControlName="personIdCardNumber"
                        id="personIdCardNumber"
                        pInputText
                      />
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
        <div [ngClass]="isEditingMode ? 'col-6' : 'col-12'">
          <p-accordion [multiple]="true" [activeIndex]="[0]">
            <p-accordionTab
              header="{{
                'animalAdd.origin.conditionOfCare.headline' | transloco
              }}"
            >
              <div class="grid">
                <div class="col-6"  *ngIf="isEditingMode || animalOrigItem?.value?.conditionOfCare?.length">
                  <label class="py-1" for="conditionBad">{{
                    'animalAdd.origin.conditionOfCare.headline' | transloco
                  }}</label>
                  <div class="flex flex-wrap gap-3 p-2">
                    <div class="flex align-items-center">
                      <p-radioButton
                        inputId="conditionBad"
                        id="conditionBad"
                        value="bad"
                        formControlName="conditionOfCare"
                      ></p-radioButton>
                      <label for="conditionBad" class="ml-2">{{
                        'animalAdd.origin.conditionOfCare.bad' | transloco
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <p-radioButton
                        inputId="conditionMedium"
                        id="conditionMedium"
                        value="medium"
                        formControlName="conditionOfCare"
                      ></p-radioButton>
                      <label for="conditionMedium" class="ml-2">{{
                        'animalAdd.origin.conditionOfCare.medium' | transloco
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <p-radioButton
                        inputId="conditionGood"
                        id="conditionGood"
                        value="good"
                        formControlName="conditionOfCare"
                      ></p-radioButton>
                      <label for="conditionGood" class="ml-2">{{
                        'animalAdd.origin.conditionOfCare.good' | transloco
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.value?.nutritionalStatus?.length">
                  <label class="py-1" for="nutritionBad">{{
                    'animalAdd.origin.nutritionalStatus.headline' | transloco
                  }}</label>
                  <div class="flex flex-wrap gap-3 p-2">
                    <div class="flex align-items-center">
                      <p-radioButton
                        inputId="nutritionBad"
                        id="nutritionBad"
                        value="bad"
                        formControlName="nutritionalStatus"
                      ></p-radioButton>
                      <label for="nutritionBad" class="ml-2">{{
                        'animalAdd.origin.nutritionalStatus.bad' | transloco
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <p-radioButton
                        inputId="nutritionMedium"
                        id="nutritionMedium"
                        value="medium"
                        formControlName="nutritionalStatus"
                      ></p-radioButton>
                      <label for="nutritionMedium" class="ml-2">{{
                        'animalAdd.origin.nutritionalStatus.medium' | transloco
                      }}</label>
                    </div>
                    <div class="flex align-items-center">
                      <p-radioButton
                        inputId="nutritionGood"
                        id="nutritionGood"
                        value="good"
                        formControlName="nutritionalStatus"
                      ></p-radioButton>
                      <label for="nutritionGood" class="ml-2">{{
                        'animalAdd.origin.nutritionalStatus.good' | transloco
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="isEditingMode || animalOrigItem?.value?.otherComments">
                  <label class="py-1" for="originOtherComments">{{
                    'animalAdd.origin.otherComments' | transloco
                  }}</label>
                  <textarea
                    pInputText
                    area
                    class="p-inputtext-sm w-full"
                    formControlName="originOtherComments"
                    id="originOtherComments"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </form>
  </div>
</ng-container>
