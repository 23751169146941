import {Subscription, take, takeWhile} from 'rxjs';
import {Component, Input, OnInit, OnDestroy, ViewChild, AfterViewInit} from '@angular/core';
import {AnimalRepository} from "../states/animal.repository";
import {AnimalService} from "../services/animal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AnimalTypeModel} from "../models/animal-type.model";
import {AnimalCoatTypeModel} from "../models/animal-coat-type.model";
import {AnimalGenderModel} from "../models/animal-gender.model";
import {AnimalEditService} from "../services/animal-edit.service";
import {AnimalModel} from "../models/animal.model";
import {ToastService} from "../../../shared/services/toast.service";
import {TranslocoService} from "@ngneat/transloco";
import {AnimalCategoryModel} from "../models/animal-category.model";
import {AnimalFilter} from "../animal-filters/animal-filter.model";
import {AnimalImagesRepository} from '../states/animal-images.repository';
import {AnimalHintModel} from "../models/animal-hint.model";
import {AnimalCharacteristicsModel} from "../models/animal-characteristics.model";
import {HelperService} from 'src/app/shared/services/helper.service';


@Component({
  selector: 'app-animal-edit-dialog',
  templateUrl: './animal-edit-dialog.component.html',
  styleUrls: ['./animal-edit-dialog.component.scss']
})
export class AnimalEditDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() animal: AnimalModel | undefined;
  @ViewChild('animalImagesRef') animalImagesRef: unknown;
  @ViewChild('animalOriginRef') animalOriginRef: any;
  visible: boolean = false;
  formGroup: FormGroup;
  selectedImgToDeleteSubscription: Subscription | undefined;
  selectedImgToDelete: number[] = [];

  alive: boolean = true;

  categoryFilters: AnimalFilter[] = [];
  animalTypeOptions: AnimalTypeModel[] = [];
  animalCategoryOptions: AnimalCategoryModel[] = [];
  coatTypeOptions: AnimalCoatTypeModel[] = [];
  genderOptions: AnimalGenderModel[] = [];
  editDialogVisible: { [tab: string]: boolean | string } = {};
  formsOriginValid: boolean = true;
  hintsOptions: AnimalHintModel[] | undefined;
  characteristicsOptions: AnimalCharacteristicsModel[] | undefined;

  constructor(
    private _animalEditService: AnimalEditService,
    private _animalService: AnimalService,
    private _animalRepository: AnimalRepository,
    private _toast: ToastService,
    private _translateService: TranslocoService,
    private _animalImagesRepository: AnimalImagesRepository,
    private _helperService: HelperService
  ) {
    this.formGroup = new FormGroup({
      type: new FormControl<string | null>(null, Validators.required),
      category: new FormControl<AnimalCategoryModel | null>(null, Validators.required),
      sex: new FormControl<AnimalGenderModel | null>(null, Validators.required),
      receiptDate: new FormControl<Date | null>(null),
      oldBookNumber: new FormControl<string | null>(null),
      name: new FormControl<string | null>(null),
      breed: new FormControl<string | null>(null, Validators.required),
      nameOld: new FormControl<string | null>(null),
      nameNew: new FormControl<string | null>(null),
      birthApprox: new FormControl<boolean | null>(false),
      birthdayType: new FormControl<boolean | null>(false),
      birthYear: new FormControl<Date | null>(null),
      birthday: new FormControl<Date | null>(null),
      coatType: new FormControl<AnimalCoatTypeModel[] | null>(null),
      coatColor: new FormControl<string | null>(null),
      size: new FormControl<number | null>(null),
      transponderNumber: new FormControl<string | null>(null),
      lifeNumber: new FormControl<string | null>(null),
      ringNumber: new FormControl<string | null>(null),
      petPassportNumber: new FormControl<string | null>(null),
      equinePassportNumber: new FormControl<string | null>(null),
      earTag: new FormControl<string | null>(null),
      tattoo: new FormControl<string | null>(null),
      tattooLocation: new FormControl<string | null>(null),
      specialInformation: new FormControl<string | null>(null),
      castrated: new FormControl<boolean>(false),
      castratedDate: new FormControl<Date | null>(null),
      castratedNote: new FormControl<string | null>(null),
      chemicalCastratedDate: new FormControl<Date | null>(null),
      sterilized: new FormControl<boolean | null>(null),
      sterilizedDate: new FormControl<Date | null>(null),
      neutered: new FormControl<boolean | null>(null),
      hints: new FormControl<AnimalHintModel[] | null>(null),
      internalDetails: new FormControl<AnimalCharacteristicsModel[] | null>(null),
      externalDetails: new FormControl<AnimalCharacteristicsModel[] | null>(null),
      externalShortDescription: new FormControl<string | null>(null),
      externalDescription: new FormControl<string | null>(null),
      syncWithWebsite: new FormControl<boolean | null>(null),
      sanctuary: new FormControl<boolean | null>(null),
      fosterHomeWanted: new FormControl<boolean>(false),
      websiteMetaDescription: new FormControl<string | null>(null),
      websiteMetaTitle: new FormControl<string | null>(null)
    });

    this.formGroup.get('castratedDate')?.valueChanges.subscribe(() => {
      this.onCastratedDatesChange();
    });

    this.formGroup.get('chemicalCastratedDate')?.valueChanges.subscribe(() => {
      this.onCastratedDatesChange();
    });
  }

  ngOnInit(): void {
    this.visible = true;
    this._animalEditService.editDialogVisible.pipe(take(1)).subscribe((openModal) => {
      this.editDialogVisible = openModal;
    });

    this.categoryFilters = this._animalService.getFilterCategories();
    this.animalCategoryOptions = this._animalService.getAnimalCategories();
    this.animalTypeOptions = this._animalService.getAnimalTypes();
    this.genderOptions = this._animalService.getGenderOptions();
    this.coatTypeOptions = this._animalService.getCoatTypes();
    this.hintsOptions = this._animalService.getHints();

    if (this.animal) {
      if (this.animal.category) {
        this.setCharacteristicOptions(this.animal.category);
      }

      this.formGroup.patchValue({
        oldBookNumber: this.animal.oldBookNumber,
        name: this.animal.name,
        nameOld: this.animal.nameOld,
        nameNew: this.animal.nameNew,
        type: this.animal.type,
        breed: this.animal.breed,
        category: this.animal.category?.toLowerCase(),
        sex: this.animal.sex,
        coatType: this.animal.coatType,
        coatColor: this.animal.coatColor,
        size: (this.animal.size !== null) ? this.animal.size / 10 : null,
        transponderNumber: this.animal.transponderNumber,
        lifeNumber: this.animal.lifeNumber,
        ringNumber: this.animal.ringNumber,
        petPassportNumber: this.animal.petPassportNumber,
        equinePassportNumber: this.animal.petPassportNumber,
        earTag: this.animal.earTag,
        tattoo: this.animal.tattoo,
        tattooLocation: this.animal.tattooLocation,
        specialInformation: this.animal.specialInformation,
        castrated: this.animal.castrated,
        castratedNote: this.animal.castratedNote,
        chemicalCastratedDate: this.animal.chemicalCastratedDate,
        sterilized: this.animal.sterilized,
        hints: this.animal.hints,
        internalDetails: this.animal.internalDetails,
        externalDetails: this.animal.externalDetails,
        externalShortDescription: this.animal.externalShortDescription,
        externalDescription: this.animal.externalDescription,
        syncWithWebsite: this.animal.syncWithWebsite,
        sanctuary: this.animal.sanctuary,
        fosterHomeWanted: this.animal.fosterHomeWanted,
        websiteMetaDescription: this.animal.websiteMetaDescription,
        websiteMetaTitle: this.animal.websiteMetaTitle
      });

      if (this.animal.castratedDate != null) {
        this.formGroup.patchValue({
          castratedDate: new Date(this.animal.castratedDate),
          castrated: true,
        });
      }

      if (this.animal.chemicalCastratedDate != null) {
        this.formGroup.patchValue({
          chemicalCastratedDate: new Date(this.animal.chemicalCastratedDate),
          castrated: true,
        });
      }

      if (this.animal.sterilizedDate != null) {
        this.formGroup.patchValue({
          sterilizedDate: new Date(this.animal.sterilizedDate),
          sterilized: true,
        });
      }

      if (this.animal.receiptDate != null) {
        this.formGroup.patchValue({
          receiptDate: new Date(this.animal.receiptDate),
        });
      }

      if (this.animal.birthday != null) {
        this.formGroup.patchValue({
          birthdayType: false,
          birthday: new Date(this.animal.birthday),
          birthYear: null,
        });
      }
      if (this.animal.birthYear != null) {
        const date = new Date(this.animal.birthYear);
        if (!isNaN(date.getTime())) {
          const year = date.getFullYear();
          const lastDay = new Date(year, 11, 31);
          this.formGroup.patchValue({
            birthdayType: true,
            birthday: null,
            birthYear: lastDay,
          });
        }
      }
    }
  }

  setCharacteristicOptions(category: string) {
    const animalFilter = this._animalService.getAnimalFilterByCategory(category);
    if (animalFilter) {
      this.characteristicsOptions = this._animalService.getCharacteristicsForCategory(animalFilter);
    }
  }

  ngAfterViewInit() {
    if (this.animalImagesRef) {
      this.selectedImgToDeleteSubscription = (this.animalImagesRef as any).selectedImgToDelete$.pipe(takeWhile(() => this.alive))
        .subscribe(
          (selectedImgToDelete: number[]) => {
            this.selectedImgToDelete = selectedImgToDelete;
          }
        );
    }
  }

  closeModal() {
    this._animalEditService.toggleEditDialog({});
  }


  checkFormsValid($event: boolean) {
    this.formsOriginValid = $event;
  }

  saveChanges() {
    if (this.animal === undefined) {
      this.closeModal();

      return;
    }

    if (this.formGroup.get('birthdayType')?.value === true) {
      this.formGroup.patchValue({birthday: null});
    } else {
      this.formGroup.patchValue({birthYear: null});
    }

    if (this.formGroup.get('size')?.value !== null && this.formGroup.get('size')?.value !== undefined && this.formGroup.get('size')?.value !== 0) {
      this.formGroup.patchValue({size: this.formGroup.get('size')?.value * 10});
    } else {
      this.formGroup.patchValue({size: null});
    }

    if (this.formGroup.get('birthdayType')?.value) {
      const year = new Date(this.formGroup.get('birthYear')?.value).getFullYear();
      const lastDay = new Date(year, 11, 31);
      this.formGroup.patchValue({
        birthday: null,
        birthYear: lastDay.toISOString()
      });
    } else {

      const birthday = this._helperService.normalizeDate(new Date(this.formGroup.get('birthday')?.value));

      this.formGroup.patchValue({
        birthday: birthday.toISOString().split('T')[0], // Hier verwenden wir nur das Datum im Format YYYY-MM-DD
        birthYear: null
      });
    }

    if (this.formGroup.get('castratedDate')?.value !== null || this.formGroup.get('chemicalCastratedDate')?.value !== null) {
      this.formGroup.patchValue({castrated: true});
    }

    if (this.formGroup.get('sterilizedDate')?.value !== null) {
      this.formGroup.patchValue({sterilized: true});
    } else {
      this.formGroup.patchValue({sterilized: false});
    }

    if (this.selectedImgToDelete.length > 0) {
      this.deleteImages();
    } else {
      this.animalOriginRef.updateAnimalOrigin();
    }

    this._animalRepository.update(this.animal.id, this.formGroup.value);

    this._animalRepository.getById(this.animal.id).subscribe((animal) => {
      this.animal = animal;
    });

    this.closeModal();
  }

  deleteImages() {
    if (this.animal === undefined) {
      return;
    }
    this.animalOriginRef.updateAnimalOrigin();
    this.selectedImgToDelete.forEach((id: number) => {
      this._animalImagesRepository.deleteImage(id).subscribe(() => {
        this._toast.showSuccess(this._translateService.translate('animalEdit.toast.deleteImagesSuccess'));
      });
    });
    this._animalRepository.getById(this.animal.id);

  }

  onCastratedDatesChange() {
    const castratedDate = this.formGroup.get('castratedDate')?.value;
    const chemicalCastratedDate = this.formGroup.get('chemicalCastratedDate')?.value;

    if (castratedDate || chemicalCastratedDate) {
      this.formGroup.get('castrated')?.setValue(true);
    } else {
      this.formGroup.get('castrated')?.setValue(false);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
