<p-dialog [(visible)]="visible" [style]="{width: '90vw', height: '90vh'}" [modal]="true" [draggable]="false"
          [resizable]="false" (onHide)="closeModal()">
  <ng-template pTemplate="closeicon">
    <span class="closeicon pi pi-times"></span>
  </ng-template>
  <ng-template pTemplate="header">
    <div class="w-full flex justify-content-center">
      <p-steps [model]="items" [readonly]="true" [activeIndex]="activeTabIndex"></p-steps>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <form id="addForm" class="h-full" [formGroup]="formGroup" (ngSubmit)="saveChanges()" novalidate>
      <div class="card m-1 p-5 h-full" *ngIf="activeTabIndex === 0">
        <div class="flex h-full align-items-center justify-content-center">
          <div class="flex flex-column align-items-center justify-content-center flex-wrap card-container">
            <h1 class="m-0 text-primary text-4xl font-semibold">Neues Tier anlegen</h1>
            <h2 class="m-0 text-color-secondary text-2xl font-semibold">Wähle eine Tierkategorie aus</h2>
            <div class="flex flex-wrap mt-5">
              <ng-container *ngFor="let filter of categoryFilters">
                <div class="flex justify-content-center align-items-stretch ml-1 animal-cat-button">
                  <p-button [ngClass]="{'selected': isSelectedCategory(filter)}"
                            (click)="selectCategory(filter)"
                            [text]="true">
                    <div class="flex flex-column">
                      <div class="flex align-items-center justify-content-center"
                           style="min-height:65px">
                        <p-image class="block mx-1" src="{{ filter.imgSrc }}" width="37"
                                 [imageStyle]="{'max-height': '45px'}"></p-image>
                      </div>
                      <div class="flex-1">
                        <p class="m-0 block font-semibold text-color-secondary font-semibold">{{ filter.title }}</p>
                      </div>
                    </div>
                  </p-button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="card m-1" *ngIf="activeTabIndex === 1">
        <div class="flex flex-row details-view">
          <div class="flex-1">
            <p-accordion [activeIndex]="[0,1]" [multiple]="true">
              <p-accordionTab [header]="'animalAdd.general.basicData.headline' | transloco">
                <div class="grid">
                  <div class="col-4">
                    <label class="py-1" for="name">{{ 'animalAdd.general.basicData.name' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="name" formControlName="name"/>
                  </div>
                  <div class="col-4">
                    <label class="py-1" for="nameOld">{{ 'animalAdd.general.basicData.nameOld' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="nameOld" formControlName="nameOld"/>
                  </div>
                  <div class="col-4">
                    <label class="py-1" for="nameNew">{{ 'animalAdd.general.basicData.nameNew' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="nameNew" formControlName="nameNew"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1"
                           for="animalCategory">{{ 'animalAdd.general.basicData.category' | transloco }}
                      <span class="required">*</span></label>
                    <p-dropdown class="p-inputdropdown-sm w-full" id="animalCategory"
                                [options]="animalCategoryOptions"
                                optionLabel="name" optionValue="value"
                                formControlName="category"
                                (onChange)="onCategoryDropdownChange($event)"
                                appendTo="body"></p-dropdown>
                  </div>
                  <div class="col-6">
                    <label class="py-1"
                           for="animalType">{{ 'animalAdd.general.basicData.type' | transloco }}
                      <span class="required">*</span></label>
                    <p-dropdown class="p-inputdropdown-sm w-full" id="animalType"
                                [ngClass]="{'p-disabled': animalTypeOptions.length === 1}"
                                [options]="animalTypeOptions"
                                optionLabel="name" optionValue="value"
                                formControlName="type"
                                appendTo="body"></p-dropdown>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="gender">
                      {{ 'animalAdd.general.basicData.sex' | transloco }}
                      <span class="required">*</span>
                    </label>
                    <div class="flex flex-wrap flex-row">
                      <div *ngFor="let genderOption of genderOptions" class="field-checkbox m-2">
                        <p-radioButton [inputId]="genderOption.key" [value]="genderOption.key"
                                       formControlName="sex" id="gender"
                                       [required]=true></p-radioButton>
                        <label [for]="genderOption.key"
                               class="ml-2">{{ genderOption.name }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="oldBookNumber">{{ 'animalAdd.general.basicData.oldBookNumber' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="oldBookNumber" formControlName="oldBookNumber"/>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab [header]="'animalAdd.general.specifications.headline' | transloco">
                <div class="grid">
                  <div class="col-12">
                    <label class="py-1"
                           for="birthDate">{{ 'animalAdd.general.specifications.birthday' | transloco }}</label>
                    <div class="flex">
                      <div class="flex align-items-center p-2">
                        {{ 'animalAdd.general.specifications.approx' | transloco }}
                        <p-inputSwitch formControlName="birthApprox"></p-inputSwitch>
                      </div>
                      <div class="flex align-items-center p-2">
                        {{ 'animalAdd.general.specifications.date' | transloco }}
                      </div>
                      <div class="flex align-items-center p-2">
                        <p-inputSwitch formControlName="birthdayType"></p-inputSwitch>
                      </div>
                      <div class="flex align-items-center p-2">
                        {{ 'animalAdd.general.specifications.year' | transloco }}
                      </div>
                      <div class="flex-1 align-items-end">
                        <p-calendar class="w-full p-inputcalendar-sm" id="birthYear"
                                    formControlName="birthYear"
                                    view="year"
                                    dateFormat="yy" [showIcon]="true"
                                    *ngIf="formGroup.get('birthdayType')?.value === true"></p-calendar>
                        <p-calendar class="w-full p-inputcalendar-sm" id="birthDate"
                                    formControlName="birthday"
                                    dateFormat="dd.mm.yy" [showIcon]="true"
                                    *ngIf="formGroup.get('birthdayType')?.value === false"></p-calendar>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="breed">
                      {{ 'animalAdd.general.specifications.breed' | transloco }}
                      <span class="required">*</span>
                    </label>
                    <input class="py-1" type="text" pInputText class="p-inputtext-sm w-full" id="breed"
                           formControlName="breed" required/>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="coatType">{{ 'animalAdd.general.specifications.coatType' | transloco }}
                    </label>
                    <p-multiSelect class="p-inputdropdown-sm w-full" id="coatType"
                                   [options]="coatTypeOptions"
                                   [style]="{'width':'100%'}"
                                   [panelStyle]="{'width':'100%'}"
                                   optionLabel="name" optionValue="value"
                                   formControlName="coatType" appendTo="body"></p-multiSelect>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="coatColor">{{ 'animalAdd.general.specifications.coatColor' | transloco }}
                    </label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="coatColor"
                           formControlName="coatColor"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="size">{{ 'animalAdd.general.specifications.size' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="size"
                           formControlName="size"/>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
          <div class="flex-1">
            <p-accordion [activeIndex]="[0,1]" [multiple]="true">
              <p-accordionTab [header]="'animalAdd.general.numbers.headline' | transloco">
                <div class="grid">
                  <div class="col-6">
                    <label class="py-1"
                           for="transponderNumber">{{ 'animalAdd.general.numbers.transponderNumber' | transloco }}
                      <span class="required">*</span></label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="transponderNumber"
                           formControlName="transponderNumber"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1"
                           for="lifeNumber">{{ 'animalAdd.general.numbers.lifeNumber' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="lifeNumber"
                           formControlName="lifeNumber"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="petPassportNumber">{{ 'animalAdd.general.numbers.petPassportNumber' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="petPassportNumber"
                           formControlName="petPassportNumber"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1"
                           for="equinePassportNumber">{{ 'animalAdd.general.numbers.equinePassportNumber' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="equinePassportNumber" formControlName="equinePassportNumber"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="ringNumber">{{ 'animalAdd.general.numbers.ringNumber' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="ringNumber" formControlName="ringNumber"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="earTag">{{ 'animalAdd.general.numbers.earTagNumber' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="earTag" formControlName="earTag"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="tattoo">{{ 'animalAdd.general.numbers.tattoo' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="tattoo" formControlName="tattoo"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="tattooLocation">{{ 'animalAdd.general.numbers.tattooLocation' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="tattooLocation" formControlName="tattooLocation"/>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab [header]="'animalAdd.general.specialInformations.headline' | transloco">
                <div class="grid">
                  <div class="col-12">
                    <label class="py-1" for="specialInformation">
                      {{ 'animalAdd.general.specialInformations.specialInformation' | transloco }}
                    </label>
                    <p-editor id="specialInformation" formControlName="specialInformation" [style]="{ height: '200px' }"></p-editor>
                  </div>
                  <div class="col-2">
                    <label class="py-1" for="castrated">{{ 'animalAdd.general.specialInformations.castrated' | transloco }}</label>
                    <p-checkbox formControlName="castrated" value="true" [binary]="true" id="castrated"></p-checkbox>
                  </div>
                  <div class="col-5">
                    <label class="py-1" for="castratedDate">{{ 'animalAdd.general.specialInformations.castratedDate' | transloco }}</label>
                    <p-calendar class="w-full p-inputcalendar-sm" id="castratedDate"
                                formControlName="castratedDate"
                                dateFormat="dd.mm.yy" [showIcon]="true"
                                appendTo="body"></p-calendar>
                  </div>
                  <div class="col-5">
                    <label class="py-1" for="sterilizedDate">{{ 'animalAdd.general.specialInformations.sterilizedDate' | transloco }}</label>
                    <p-calendar class="w-full p-inputcalendar-sm" id="sterilizedDate"
                                formControlName="sterilizedDate"
                                dateFormat="dd.mm.yy" [showIcon]="true"
                                appendTo="body"></p-calendar>
                  </div>
                  <div class="col-12">
                    <label class="py-1" for="chemicalCastratedDate">{{ 'animalAdd.general.specialInformations.chemicalCastratedDate' | transloco }}</label>
                    <p-calendar class="w-full p-inputcalendar-sm" id="chemicalCastratedDate"
                                formControlName="chemicalCastratedDate"
                                dateFormat="dd.mm.yy" [showIcon]="true"
                                appendTo="body"></p-calendar>
                  </div>
                  <div class="col-12">
                    <label class="py-1" for="castratedNote">{{ 'animalAdd.general.specialInformations.castratedNote' | transloco }}</label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="castratedNote" formControlName="castratedNote"/>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="flex flex-row details-view">
          <div class="flex-1">
            <p-accordion [activeIndex]="[0,1]" [multiple]="true">
              <p-accordionTab [header]="'animalAdd.general.websiteInformations.headline' | transloco">
                <div class="grid">
                  <div class="col-2">
                    <label class="py-1" for="syncWithWebsite">
                      {{ 'animalAdd.general.websiteInformations.syncWithWebsite' | transloco }}
                    </label>
                    <p-checkbox
                        formControlName="syncWithWebsite"
                        value="true"
                        [binary]="true"
                        id="syncWithWebsite"
                        inputId="syncWithWebsite"
                    ></p-checkbox>
                  </div>
                  <div class="col-2">
                    <label class="py-1" for="fosterHomeWanted">{{ 'animalAdd.general.websiteInformations.fosterHomeWanted' | transloco }}</label>
                    <p-toggleButton
                      formControlName="fosterHomeWanted"
                      id="fosterHomeWanted"
                      onLabel="{{ 'yes' | transloco }}"
                      offLabel="{{ 'no' | transloco }}" />
                  </div>
                  <div class="col-1">
                    <label class="py-1" for="sanctuary">{{ 'animalAdd.general.websiteInformations.sanctuary' | transloco }}</label>
                    <p-toggleButton
                      formControlName="sanctuary"
                      id="sanctuary"
                      onLabel="{{ 'yes' | transloco }}"
                      offLabel="{{ 'no' | transloco }}" />
                  </div>
                  <div class="col-7">
                    <label class="py-1" for="externalShortDescription">
                      {{ 'animalAdd.general.websiteInformations.externalShortDescription' | transloco }}
                    </label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="externalShortDescription"
                           formControlName="externalShortDescription"/>
                  </div>
                  <div class="col-12">
                    <label class="py-1" for="externalDescription">
                      {{ 'animalAdd.general.websiteInformations.externalDescription' | transloco }}
                    </label>
                    <p-editor id="externalDescription" formControlName="externalDescription" [style]="{ height: '200px' }"></p-editor>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="hints">{{ 'animalAdd.general.specialInformations.hints' | transloco }}</label>
                    <p-multiSelect class="p-inputdropdown-sm w-full" id="hints"
                                   [options]="hintsOptions"
                                   optionLabel="name" optionValue="value"
                                   formControlName="hints"
                                   [style]="{'width':'400px'}"
                                   [panelStyle]="{'width':'400px'}" appendTo="body">
                    </p-multiSelect>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6">
                    <label class="py-1" for="internalDetails">
                      {{ 'animalAdd.general.specialInformations.characteristics.headline' | transloco }}
                      ({{ 'animalAdd.general.specialInformations.characteristics.internal' | transloco }})
                    </label>
                    <p-multiSelect class="p-inputdropdown-sm w-full" id="internalDetails"
                                   [options]="characteristicsOptions"
                                   optionLabel="name" optionValue="value"
                                   formControlName="internalDetails"
                                   [style]="{'width':'400px'}"
                                   [panelStyle]="{'width':'400px'}" appendTo="body">
                    </p-multiSelect>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="externalDetails">
                      {{ 'animalAdd.general.specialInformations.characteristics.headline' | transloco }}
                      ({{ 'animalAdd.general.specialInformations.characteristics.external' | transloco }})
                    </label>
                    <p-multiSelect class="p-inputdropdown-sm w-full" id="externalDetails"
                                   [options]="characteristicsOptions"
                                   optionLabel="name" optionValue="value"
                                   formControlName="externalDetails"
                                   [style]="{'width':'400px'}"
                                   [panelStyle]="{'width':'400px'}" appendTo="body">
                    </p-multiSelect>
                  </div>
                  <div class="col-12">
                    <label class="py-1" for="websiteMetaDescription">
                      {{ 'animalAdd.general.websiteInformations.websiteMetaDescription' | transloco }}
                    </label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="websiteMetaDescription"
                           formControlName="websiteMetaDescription"/>
                  </div>
                  <div class="col-12">
                    <label class="py-1" for="websiteMetaTitle">
                      {{ 'animalAdd.general.websiteInformations.websiteMetaTitle' | transloco }}
                    </label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="websiteMetaTitle"
                           formControlName="websiteMetaTitle"/>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </div>
      <div class="card m-1" *ngIf="activeTabIndex === 2">
        <div class="grid details-view">
          <div class="col-6">
            <div class="grid">
              <div class="col-6">
                <label class="py-1" for="originCompetentAuthority">
                  {{ 'animalAdd.origin.competentAuthority' | transloco }}
                  <span class="required">*</span>
                </label>
                <p-dropdown
                    class="p-inputdropdown-sm w-full"
                    id="originCompetentAuthority"
                    [options]="originCompetentAuthorityOptions"
                    optionLabel="name"
                    optionValue="id"
                    [autoDisplayFirst]="false"
                    formControlName="originCompetentAuthority"
                    [required]="true"
                    appendTo="body">
                </p-dropdown>
              </div>
              <div class="col-6">
                <label class="py-1" for="originLocation">
                  {{ 'animalAdd.origin.location' | transloco }}
                  <span class="required">*</span>
                </label>
                <input type="text" pInputText class="p-inputtext-sm w-full" id="originLocation"
                       formControlName="originLocation" required/>
              </div>
              <div class="col-6">
                <label class="py-1" for="originReasonForTransfer">
                  {{ 'animalAdd.origin.reasonForTransfer' | transloco }}
                  <span class="required">*</span>
                </label>
                <p-dropdown
                    class="p-inputdropdown-sm w-full"
                    id="originReasonForTransfer"
                    [options]="originReasonForTransfer"
                    optionLabel="name" optionValue="value"
                    [autoDisplayFirst]="false"
                    formControlName="originReasonForTransfer"
                    [required]="true"
                    appendTo="body">
                </p-dropdown>
              </div>
              <div class="col-6">
                <label class="py-1">
                  {{ 'animalAdd.origin.dateOfReceipt' | transloco }}
                  <span class="required">*</span>
                </label>
                <p-calendar class="w-full p-inputcalendar-sm" id="originDateOfReceipt"
                            formControlName="originDateOfReceipt"
                            dateFormat="dd.mm.yy" [showIcon]="true"
                            appendTo="body"></p-calendar>
              </div>
              <div class="col-12">
                <label class="py-1" for="originSpecialInformation">
                  {{ 'animalAdd.origin.specialInformation' | transloco }}
                </label>
                <textarea
                  area
                  pInputText
                  class="p-inputtext-sm w-full"
                  id="originSpecialInformation"
                  formControlName="originSpecialInformation"
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="grid">
              <div class="col-3">
                <label class="py-1"
                        for="originPersonSalutationOptions">{{ 'animalAdd.origin.person.salutation' | transloco }}
                  </label>
                <p-dropdown
                  class="p-inputdropdown-sm w-full"
                  id="originPersonSalutationOptions"
                  [options]="originPersonSalutationOptions"
                  optionLabel="name"
                  optionValue="value"
                  formControlName="originPersonSalutation"
                  appendTo="body"></p-dropdown>
              </div>
              <div class="col-4">
                <label class="py-1"
                        for="originPersonLastName">{{ 'animalAdd.origin.person.lastName' | transloco }}
                  </label>
                <input type="text" pInputText class="p-inputtext-sm w-full"
                        id="originPersonLastName"
                        formControlName="originPersonLastName"/>
              </div>
              <div class="col-5">
                <label class="py-1"
                        for="originPersonFirstName">{{ 'animalAdd.origin.person.firstName' | transloco }}
                  </label>
                <input type="text" pInputText class="p-inputtext-sm w-full"
                        id="originPersonFirstName"
                        formControlName="originPersonFirstName"/>
              </div>
              <div class="col-12">
                <label class="py-1"
                        for="originPersonCompanyName">{{ 'animalAdd.origin.person.companyName' | transloco }}
                  </label>
                <input type="text" pInputText class="p-inputtext-sm w-full"
                        id="originPersonCompanyName"
                        formControlName="originPersonCompanyName"/>
              </div>
              <div class="col-5">
                <label class="py-1"
                        for="originPersonStreet">{{ 'animalAdd.origin.person.street' | transloco }}
                  </label>
                <input type="text" pInputText class="p-inputtext-sm w-full" id="originPersonStreet"
                        formControlName="originPersonStreet"/>
              </div>
              <div class="col-2">
                <label class="py-1"
                        for="originPersonZipCode">{{ 'animalAdd.origin.person.zipCode' | transloco }}
                  </label>
                <input type="text" pInputText class="p-inputtext-sm w-full" id="originPersonZipCode"
                        formControlName="originPersonZipCode"/>
              </div>
              <div class="col-5">
                <label class="py-1"
                        for="originPersonCity">{{ 'animalAdd.origin.person.city' | transloco }}
                  </label>
                <input type="text" pInputText class="p-inputtext-sm w-full" id="originPersonCity"
                        formControlName="originPersonCity"/>
              </div>
              <div class="col-6">
                <label class="py-1"
                        for="originPersonMobileNumber">{{ 'animalAdd.origin.person.mobileNumber' | transloco }}</label>
                <input type="text" pInputText class="p-inputtext-sm w-full"
                        id="originPersonMobileNumber"
                        formControlName="originPersonMobileNumber"/>
              </div>
              <div class="col-6">
                <label class="py-1"
                        for="originPersonPhoneNumber">{{ 'animalAdd.origin.person.phoneNumber' | transloco }}</label>
                <input type="text" pInputText class="p-inputtext-sm w-full"
                        id="originPersonPhoneNumber"
                        formControlName="originPersonPhoneNumber"/>
              </div>
              <div class="col-6">
                <label class="py-1"
                        for="originPersonEmail">{{ 'animalAdd.origin.person.email' | transloco }}</label>
                <input type="text" pInputText class="p-inputtext-sm w-full" id="originPersonEmail"
                        formControlName="originPersonEmail"/>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label class="py-1">{{ 'animalAdd.origin.conditionOfCare.headline' | transloco }}</label>
            <div class="flex flex-wrap gap-3 p-2">
              <div class="flex align-items-center">
                <p-radioButton inputId="conditionBad" id="conditionBad" value="bad"
                               formControlName="originConditionOfCare"></p-radioButton>
                <label for="conditionBad"
                       class="ml-2">{{ 'animalAdd.origin.conditionOfCare.bad' | transloco }}</label>
              </div>
              <div class="flex align-items-center">
                <p-radioButton inputId="conditionMedium" id="conditionMedium" value="medium"
                               formControlName="originConditionOfCare"></p-radioButton>
                <label for="conditionMedium"
                       class="ml-2">{{ 'animalAdd.origin.conditionOfCare.medium' | transloco }}</label>
              </div>
              <div class="flex align-items-center">
                <p-radioButton inputId="conditionGood" id="conditionGood" value="good"
                               formControlName="originConditionOfCare"></p-radioButton>
                <label for="conditionGood"
                       class="ml-2">{{ 'animalAdd.origin.conditionOfCare.good' | transloco }}</label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label class="py-1">{{ 'animalAdd.origin.nutritionalStatus.headline' | transloco }}</label>
            <div class="flex flex-wrap gap-3 p-2">
              <div class="flex align-items-center">
                <p-radioButton inputId="nutritionBad" id="nutritionBad" value="bad"
                               formControlName="originNutritionalStatus"></p-radioButton>
                <label for="nutritionBad"
                       class="ml-2">{{ 'animalAdd.origin.nutritionalStatus.bad' | transloco }}</label>
              </div>
              <div class="flex align-items-center">
                <p-radioButton inputId="nutritionMedium" id="nutritionMedium" value="medium"
                               formControlName="originNutritionalStatus"></p-radioButton>
                <label for="nutritionMedium"
                       class="ml-2">{{ 'animalAdd.origin.nutritionalStatus.medium' | transloco }}</label>
              </div>
              <div class="flex align-items-center">
                <p-radioButton inputId="nutritionGood" id="nutritionGood" value="good"
                               formControlName="originNutritionalStatus"></p-radioButton>
                <label for="nutritionGood"
                       class="ml-2">{{ 'animalAdd.origin.nutritionalStatus.good' | transloco }}</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <label class="py-1"
                   for="originOtherComments">{{ 'animalAdd.origin.otherComments' | transloco }}</label>
            <textarea pInputText class="p-inputtext-sm w-full" area id="originOtherComments"
                      formControlName="originOtherComments" rows="5"></textarea>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1">
        <button class="p-button" (click)="lastStep()"
                [disabled]="activeTabIndex === 0"
                data-cy="last-step-btn">{{ 'animalAdd.lastStep' | transloco }}
        </button>
        <button *ngIf="activeTabIndex !== 2" class="p-button" (click)="nextStep()"
                [disabled]="activeTabIndex === 2 || (activeTabIndex === 0 && selectedCategory === undefined)"
                data-cy="next-step-btn">{{ 'animalAdd.nextStep' | transloco }}
        </button>
        <button *ngIf="activeTabIndex === 2" class="p-button" form="addForm" type="submit"
                [disabled]="formGroup.invalid"
                data-cy="submit-btn">{{ 'modal.save' | transloco }}
        </button>
      </div>
    </div>
    <div class="flex-end">
      <span class="required">* {{ 'animalAdd.general.required' | transloco }}</span>
    </div>
  </ng-template>
</p-dialog>
