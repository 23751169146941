import {take, takeWhile} from 'rxjs';
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {AnimalRepository} from "../states/animal.repository";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AnimalEditService} from "../services/animal-edit.service";
import {AnimalModel} from "../models/animal.model";
import {AnimalShelterModel} from "../models/animal-shelter.model";
import {AnimalSheltersRepository} from "../states/animal-shelter.repository";
import {AnimalOriginRepository} from "../states/animal-origin.repository";
import {AnimalWhereaboutsRepository} from "../states/animal-whereabouts.repository";
import {Router} from "@angular/router";


@Component({
  selector: 'app-animal-move-dialog',
  templateUrl: './animal-move-dialog.component.html',
  styleUrls: ['./animal-move-dialog.component.scss']
})
export class AnimalMoveDialogComponent implements OnInit, OnDestroy {
  @Input() animal: AnimalModel | undefined;
  formGroup: FormGroup;

  alive: boolean = true;
  moveDialogVisible: boolean = false;
  shelters: AnimalShelterModel[] | undefined;
  selectedShelter: AnimalShelterModel | undefined;

  constructor(
    private readonly _animalEditService: AnimalEditService,
    private readonly _animalRepository: AnimalRepository,
    private readonly _animalShelterRepository: AnimalSheltersRepository,
    private _router: Router
  ) {
    this.formGroup = new FormGroup({
      animalShelter: new FormControl<AnimalShelterModel | null>(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this._animalEditService.moveDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.moveDialogVisible = openModal;
    });

    if (this.animal) {
      this.formGroup.patchValue({
        test: this.animal.oldBookNumber,
      });
    }

    this._animalShelterRepository.getAnimalShelters().pipe(take(1)).subscribe(shelters => {
      this.shelters = shelters;
    });

    this.selectedShelter = this.animal?.animalShelter;
  }

  closeModal() {
    this._animalEditService.toggleMoveDialog();
  }

  saveChanges() {
    if (this.animal === undefined) {
      this.closeModal();

      return;
    }

    this._animalRepository.update(this.animal.id, this.formGroup.value).pipe().subscribe((animal) => {
      this._animalRepository.getAll()
      this._router.navigate(['/tiere']);

    });

    this.closeModal();
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
